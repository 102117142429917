import { CONFIG } from './config'

// cat *stems.txt | cut -d' ' -f1 | grep -o -w '\w\{5,5\}' > wordle-5.txt
export const WORDS = [
  'wayês',
  'kisîw',
  'pâkan',
  'mênis',
  'niska',
  'nêwâw',
  'sêham',
  'ohpim',
  'piyis',
  'awêkâ',
  'kîpîw',
  'niyâk',
  'îkatê',
  'nitêh',
  'wâwîs',
  'itowa',
  'âhpin',
  'namôy',
  'pîwan',
  'wîniy',
  'pîtos',
  'nisîm',
  'âhkik',
  'âtiht',
  'mîkis',
  'akâwâ',
  'pwâta',
  'atâhk',
  'wâkâs',
  'pihko',
  'apwêw',
  'êskêw',
  'pîsim',
  'astis',
  'wîhêw',
  'mâham',
  'opîma',
  'wahwâ',
  'âsitê',
  'mômêw',
  'êcika',
  'nôniw',
  'pêyak',
  'kîsâc',
  'mîsîw',
  'yâwâw',
  'sêwêw',
  'timîw',
  'êskwa',
  'nisto',
  'sakâs',
  'pipon',
  'êwako',
  'iskwa',
  'nipâw',
  'sîpîw',
  'niyaw',
  'wîpis',
  'yêkaw',
  'tômâw',
  'êkotê',
  'wîkiw',
  'apisk',
  'nîpîs',
  'wapâw',
  'pimiy',
  'nitêm',
  'nawac',
  'kîsik',
  'awîta',
  'nisis',
  'nipiy',
  'mayaw',
  'askâw',
  'atihk',
  'mowac',
  'nâpêw',
  'pisîs',
  'nâpês',
  'wakîc',
  'pâkâw',
  'wiyâs',
  'natay',
  'pisin',
  'kipiw',
  'kapâw',
  'yôtin',
  'pôyow',
  'kisin',
  'kêkâc',
  'cîsas',
  'mwâsi',
  'pôsiy',
  'sîwâw',
  'wâyâw',
  'kâwiy',
  'payâk',
  'wîpat',
  'awahê',
  'minôs',
  'apwân',
  'pônîw',
  'sîpan',
  'misoy',
  'pêtâw',
  'mêkiw',
  'êkamâ',
  'wâsâw',
  'nitâs',
  'tênam',
  'tâwic',
  'tahki',
  'paskê',
  'cîwêw',
  'mînis',
  'ayisk',
  'sôhki',
  'kipoc',
  'pônêw',
  'nimis',
  'kêkoc',
  'macan',
  'kâsôw',
  'kâpôs',
  'sînêw',
  'pômêw',
  'mikot',
  'pônam',
  'wiyin',
  'êhâhâ',
  'nîmâw',
  'oskac',
  'nacâs',
  'mikiy',
  'katâc',
  'ispic',
  'manîw',
  'êkâya',
  'tôman',
  'macâs',
  'awiyâ',
  'waciy',
  'kîwâc',
  'sâsîw',
  'ohtêw',
  'pisiw',
  'aniki',
  'nikot',
  'wîsta',
  'nôhêw',
  'mahti',
  'mosis',
  'mîpit',
  'nîpit',
  'kihîw',
  'kanak',
  'sâsay',
  'ihtiw',
  'mitôn',
  'âskaw',
  'wâcis',
  'sâwan',
  'âsônê',
  'sîwâs',
  'cihkê',
  'âpiht',
  'mikiw',
  'kitâw',
  'pasow',
  'sikiw',
  'kisik',
  'mîciw',
  'cîmân',
  'ahpin',
  'wâwâc',
  'anitê',
  'yâkwâ',
  'kâkwa',
  'kayâm',
  'miyêw',
  'aniyê',
  'tasôw',
  'nîpin',
  'sikâk',
  'sîpiy',
  'nâsic',
  'kiyâm',
  'âkwâc',
  'tâwiw',
  'kêcîw',
  'astêw',
  'askoc',
  'kitow',
  'acici',
  'sihta',
  'anohc',
  'mînôw',
  'aspin',
  'watôw',
  'ohcîw',
  'askiy',
  'ahtay',
  'môswa',
  'sîsîp',
  'pâtos',
  'têham',
  'mitiy',
  'wîpac',
  'sêmâk',
  'nisoy',
  'êtokê',
  'miyay',
  'pêhow',
  'wîpâw',
  'ispâw',
  'môsak',
  'cîpay',
  'pôsîw',
  'awîtê',
  'nikik',
  'sêkwâ',
  'wâpos',
  'wâwis',
  'mîcim',
  'yâyaw',
  'pîmâw',
  'tôtam',
  'mihti',
  'sômin',
  'kîpan',
  'nîwas',
  'itwêw',
  'kîmîw',
  'môhêw',
  'matay',
  'ohpan',
  'pîway',
  'masân',
  'anisi',
  'kihci',
  'mitêh',
  'mîtos',
  'ayihk',
  'ihtâw',
  'kâtêw',
  'pôsîs',
  'êkosi',
  'nahîw',
  'asici',
  'yêhêw',
  'kâsak',
  'kîmôc',
  'kocîw',
  'wiyaw',
  'amisk',
  'yâwêw',
  'mitêw',
  'âhcîw',
  'mohci',
  'nîkis',
  'âspîs',
  'matêw',
  'pakân',
  'nitôn',
  'tâpic',
  'nisôs',
  'miyôw',
  'pîwâs',
  'namêw',
  'sâkôc',
  'anita',
  'nâtêw',
  'nipiw',
  'wâhay',
  'êhâha',
  'pisik',
  'sêhkê',
  'sôkâw',
  'nîsta',
  'astâw',
  'ihkin',
  'nîmiw',
  'tipân',
  'wâkâw',
  'yâpêw',
  'mitâs',
  'kêkât',
  'sînam',
  'nawat',
  'êskan',
  'nipîs',
  'kayâs',
  'oswêw',
  'nêyâw',
  'omisi',
  'nôtin',
  'misit',
  'anima',
  'nâtam',
  'wâpan',
  'oskan',
  'pikiw',
  'anihi',
  'mâtow',
  'sakâw',
  'nîkân',
  'pâsow',
  'pipik',
  'akask',
  'sêkôw',
  'akohp',
  'mâcîw',
  'mihko',
  'cêsos',
  'kâsâw',
  'wâpiw',
  'piciw',
  'wayân',
  'ohtin',
  'ihkwa',
  'mowêw',
  'ohpîw',
  'misâw',
  'awîna',
  'tahto',
  'tâpwê',
  'pêhêw',
  'âstam',
  'âsowê',
  'sâkiy',
  'pimîs',
  'kîhêw',
  'nisit',
  'pihêw',
  'pâsam',
  'wâpâw',
  'êkota',
  'anoht',
  'tawâw',
  'sôkâs',
  'kîwêw',
  'namês',
  'otâhk',
  'watow',
  'kâwâw',
  'mosci',
  'mâcîs',
  'nitiy',
  'nakîw',
  'mâkwa',
  'nîtim',
  'pôsiw',
  'mahci',
  'akinê',
  'opwâm',
  'kihêw',
  'kîsta',
  'pasân',
  'nîpiy',
  'wîwiw',
  'wâskâ',
  'ayahk',
  'kihiw',
  'wîsti',
  'kâtâw',
  'nâway',
  'côcôs',
  'miyaw',
  'niyâw',
  'kôkîw',
  'pimic',
  'êyiwê',
  'nêmow',
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
